import { useState, useEffect } from "react";

const colors = [
  "#FF5F6D",
  "#ff646d",
  "#ff716d",
  "#ff7b6e",
  "#ff856f",
  "#ff946f",
  "#ffa070",
  "#ffac71",
  "#ffba70",
  "#FFC371",
];

const Words = ({ words }: { words: string[] }) => {
  const [word, setWord] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const max = words.length;
      const next = word + 1 >= max ? 0 : word + 1;
      setWord(next);
    }, 1500);
  }, [word]);

  return <span>{words[word]}</span>;
};

export default Words;

//style={{color: colors[word], textShadow: '2px 2px 2px white'}}
