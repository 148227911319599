import { useEffect } from "react";
import type { NextPage } from "next";
import styles from "../styles/Home.module.css";
import Button from "@mui/material/Button";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { RecordVoiceOverTwoTone } from "@mui/icons-material";
import { AccessibilityNewTwoTone } from "@mui/icons-material";
import SpeedIcon from "@mui/icons-material/Speed";
import FormatListNumberedTwoToneIcon from "@mui/icons-material/FormatListNumberedTwoTone";
import StarTwoTone from "@mui/icons-material/StarTwoTone";
import Card from "../components/cards";
import Typography from "@mui/material/Typography";
import Head from "next/head";
import Search from "../components/search";
import { useRouter } from "next/router";
import Link from "next/link";
import Words from "../components/words";

const Home: NextPage = () => {
  const controls = useAnimation();
  const router = useRouter();
  const [ref, inView] = useInView();

  const variants = {
    visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const handleSearchChange = (searchVals: any) => {
    if (searchVals.newOption) {
      //go to make picks
      router.push(
        `/make-picks?toppick=${searchVals.value}&toppick_description=${searchVals.label}`
      );
    } else {
      const [toppickId, locationId] = searchVals.value.split("___");
      router.push(`/picks/${toppickId}/${locationId}`);
    }
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <>
      <Head>
        <title>TOPPICK</title>
        <meta property="og:title" content="TOPPICK Home Page" />
        <meta
          property="og:image"
          content={`https://r2.getluva.workers.dev/meta_logo.png`}
        />
        <meta
          property="og:description"
          content="The definitive rankings for everything, by everyone. Make your picks today."
        />
        <meta property="og:url" content="//toppick.com" />
        <meta property="fb:app_id" content="3028797174077916" />
      </Head>
      <div className={`${styles.container} ${styles.home}`}>
        <div className={styles.top}>
          <h2 style={{ textAlign: "center", color: "white", marginTop: -20 }}>
            Recommendations <i>for everything</i>, from everyone
          </h2>
          <div style={{ maxWidth: "80%", margin: "auto" }}>
            <div
              style={{
                margin: "auto",
                textAlign: "center",
                marginBottom: 24,
                maxWidth: 400,
              }}
            >
              <Search
                style={{ padding: 8, fontSize: 24 }}
                minWidth={240}
                placeholder="Search Toppicks"
                onChange={handleSearchChange}
                resetMode={true}
              />
            </div>
          </div>
          <h1 className={styles.headline}>TOPPICK</h1>
        </div>
        <section
          style={{
            padding: "2rem",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Card
            index={0}
            styles={{ maxWidth: "20vw", margin: 8, minWidth: 250 }}
          >
            <StarTwoTone style={{ fontSize: 128, color: "#FF5F6D" }} />
            <p>
              Reviews are the worst. They are paid for, bought, and everything
              is 4.5/5. What do they really tell you? Nothing. There&apos;s a
              better way.
            </p>
          </Card>
          <Card
            index={1}
            styles={{ maxWidth: "20vw", margin: 8, minWidth: 250 }}
          >
            <AccessibilityNewTwoTone
              style={{ fontSize: 128, color: "#ff716d" }}
            />
            <p>
              People are different (shocking, we know). What may be the top pick
              for some, may be the opposite for others. Find recommendations
              from people like you.
            </p>
          </Card>
          <Card
            index={2}
            styles={{ maxWidth: "20vw", margin: 8, minWidth: 250 }}
          >
            <FormatListNumberedTwoToneIcon
              style={{ fontSize: 128, color: "#ff856f" }}
            />
            <p>
              Reviews are not recommendations. If everything is 5 stars, nothing
              is. TOPPICK forces rankings, which give you true recommendations.
            </p>
          </Card>
          <Card
            index={3}
            styles={{ maxWidth: "20vw", margin: 8, minWidth: 250 }}
          >
            <RecordVoiceOverTwoTone
              style={{ fontSize: 128, color: "#ffa070" }}
            />
            <p>
              TOPPICK is the place to find true word of mouth style
              recommendations from everyone. It&apos;s endlessly fascinating and
              rewarding.
            </p>
          </Card>
          <Card
            index={4}
            styles={{ maxWidth: "20vw", margin: 8, minWidth: 250 }}
          >
            <SpeedIcon style={{ fontSize: 128, color: "#FFC371" }} />
            <p>
              Quickly find the toppicks you&apos;re interested in and easily
              make your own picks to add to the most interesting website on the
              planet (it&apos;s our top pick).
            </p>
          </Card>
        </section>
        <div className={styles.stock}>
          <div
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "10vw",
              textAlign: "center",
              lineHeight: "11vw",
              background: "#0005",
            }}
          >
            Top Pizza Restaurants in Your Town According to{" "}
            <div>
              <Words
                words={[
                  "Italians",
                  "Moms",
                  "Locals",
                  "Wealthy People",
                  "Short People",
                ]}
              />
            </div>
          </div>
        </div>
        <div
          className={"easyRead"}
          style={{ margin: "auto", maxWidth: 600, padding: 40 }}
        >
          <Typography variant="body1">
            All the restaurants on Yelp have the same review total score. On
            Google maps everything is 4.5/5 and google decides what to show me
            anyway. On Amazon it&apos;s impossible to trust any review in 2022.
            We lost faith in reviews years ago. <br />
            <br />
            What we want is recommendations. BUT, recommendations are hard to
            come by. How can you get real recommendations from locals in cities
            you&apos;ve never been to? Or recommendations from people with
            similar life circumstances? TOPPICK opens up a whole new world of
            free, online, recommendations.
          </Typography>
        </div>
        <div
          style={{ textAlign: "center", margin: "48px auto", maxWidth: "80%" }}
        >
          <Typography variant="subtitle1" style={{ paddingBottom: 12 }}>
            Share your preferences to help others live a more rewarding life
          </Typography>
          <Link href="/make-picks">
            <Button variant="contained" color="secondary">
              Make Picks
            </Button>
          </Link>
        </div>
        <div className={styles.stock2}>
          <div
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "10vw",
              textAlign: "center",
              lineHeight: "11vw",
              background: "#0005",
            }}
          >
            Top Books in 2022 According to{" "}
            <div>
              <Words
                words={[
                  "Engineers",
                  "Yankee Fans",
                  "Mexicans",
                  "Home Owners",
                  "Pet Owners",
                ]}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            maxWidth: 400,
            margin: "auto",
            marginTop: 48,
            padding: "0 24px",
          }}
        >
          <section>
            <Typography variant="h6">Rankings = Recommendations</Typography>
            <div className={"easyRead"}>
              <Typography variant="body1">
                Our secret sauce is simple (please don&apos;t steal it...). We
                make it impossible for people to give every item 5 stars, by
                asking people for rankings. Top ranked items (top picks), are
                the real recommendation for any topic (toppick). We aggregate
                toppicks and create recommendations for everything, from
                everyone.
              </Typography>
            </div>
            <div style={{ textAlign: "center", paddingTop: 12 }}>
              <Link href="/make-picks">
                <Button variant="contained" color="secondary">
                  Make Picks
                </Button>
              </Link>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Home;
