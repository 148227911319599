import React, { useEffect } from "react";
import { Paper } from "@mui/material";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Card = ({
  children,
  index,
  styles,
}: {
  children: any;
  index: number;
  styles: any;
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 1, delay: index * 0.33 },
    },
    hidden: { opacity: 0, scale: 0 },
  };

  useEffect(() => {
    const delay = index * 1000;
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      initial="hidden"
      variants={variants}
      animate="visible"
      className="section"
      style={{ ...styles }}
      ref={ref}
    >
      <Paper
        elevation={3}
        style={{
          margin: 8,
          textAlign: "center",
          color: "white",
          backgroundColor: "#354969",
          padding: 8,
          height: "100%",
          overflow: "hidden",
        }}
      >
        {children}
      </Paper>
    </motion.div>
  );
};

export default Card;
